import React, {Fragment} from 'react';
import { Link, NavLink } from "react-router-dom";
import Routes from './Routes';
import { withRouter } from 'react-router';
import { Auth } from "aws-amplify";
import "../node_modules/react-bootstrap/dist/react-bootstrap.min.js";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false
        }
    }
    userHasAuthenticated = (value) => {
        this.setState({ isAuthenticated: value });
    }

    handleLogout = async event => {
        await Auth.signOut();
        this.userHasAuthenticated(false);
        this.props.history.push("/login");
    }

    async componentDidMount() {
        try {
          await Auth.currentSession();
          this.userHasAuthenticated(true);
          this.props.history.push("/casamiabackend2");
        }
        catch(e) {
          if (e !== 'No current user') {
            alert(e);
          }
        }
    }
    render(){
        return (
            <Fragment>
                <div className="navbar navbar-expand-md navbar-light bg-light">
                    <Link to="/" className="navbar-brand" href="#">
                    <i class="fas fa-home fa-lg">&nbsp;Casamia</i>
                    </Link>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {this.state.isAuthenticated ? 
                                <Fragment>
                                    <li className="nav-item">
                                        <NavLink to="/" onClick={this.handleLogout}>Logout</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/casamiabackend1" className="nav-link">Nuovo trattamento</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/casamiabackend2" className="nav-link">Tutti i trattamenti</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to="/casamiabackend3" className="nav-link">Vedi trattamento</NavLink>
                                    </li> */}                          
                                </Fragment> : 
                                <Fragment>
                                    <li className="nav-item">
                                        <NavLink to="/login" className="nav-link">Login</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/signup" className="nav-link">Signup</NavLink>
                                    </li>
                                </Fragment>
                            } 
                        </ul>
                    </div>                   
                </div>
                <Routes userHasAuthenticated={this.userHasAuthenticated} isAuthenticated = {this.state.isAuthenticated}/>
            </Fragment>
        );
    }
}

export default withRouter(App);
