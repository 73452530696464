import React from 'react';
import ReactDOM from 'react-dom';
//import {Router,Route} from "react-router";
import { BrowserRouter as Router} from "react-router-dom";
import App from './App';
import Footer from './Footer';
import './index.css';
import * as serviceWorker from './serviceWorker';
import config from "./config";
import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
      endpoints: [
          {
              name: "casamia",
              endpoint: config.apiGateway.URL,
              region: config.apiGateway.REGION
          },
      ]
  }
});

ReactDOM.render(<Router><App /><Footer firstrow="&copy;2021 G.Adeferri" /></Router>, document.getElementById('root'));

serviceWorker.unregister();