import React, {Fragment} from 'react';
//import {Button} from 'react-bootstrap';
import { Form, Row, Col, FormGroup, FormControl, FormLabel, Button } from "react-bootstrap";
//import CKEditor from '@ckeditor/ckeditor5-react';
//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import { API,Auth } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const userIdContent = "";
const userTreatmentIdContent = "";
const format = 'HH:mm';

export default class CreateTreatment extends React.Component {
   constructor(props) {
       super(props);
       this.state = {
          userIdData: userIdContent,
          userTreatmentIdData: userTreatmentIdContent,
          userTreatmentFromData: "",
          userTreatmentToData: "",
          userItemIdData: "",
          userItemDayOfWeekData: "",
          userItemTimeData: "",
          userItemDrugData: "",
          userItemDoseData: ""
       }
   };
  
   handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

   onChangeFrom = userTreatmentFromData => this.setState({ userTreatmentFromData })
   onChangeTo = userTreatmentToData => this.setState({ userTreatmentToData })
   onChangeTime = userItemTimeData => this.setState({ userItemTimeData })
  
   validateTreatment = () =>  {
        return this.state.userTreatmentIdData != "" &&
        this.state.userTreatmentFromData != "" &&
        this.state.userTreatmentToData != "" &&
        this.state.userItemDayOfWeekData != "" &&
        this.state.userItemTimeData != "" &&
        this.state.userItemDrugData.length > 0 && 
        this.state.userItemDoseData.length > 0;
   }
   createNew = async event => {
        event.preventDefault();
        // form validation
        if (!this.validateTreatment())
            {alert("inserire tutti i dati");}
        else{
            let sessionObject = await Auth.currentSession();
            let idToken = sessionObject.idToken.jwtToken;
            let userid = sessionObject.idToken.payload.sub;
            // setting userIdData
            this.setState({ userIdData: userid})
            let treatment = {
                userID: this.state.userIdData,
                userTreatmentId: this.state.userTreatmentIdData,
                userTreatmentFrom: this.state.userTreatmentFromData,
                userTreatmentTo: this.state.userTreatmentToData,
                userItemId: this.state.userItemIdData,
                userItemDayOfWeek: this.state.userItemDayOfWeekData,
                userItemTime: this.state.userItemTimeData,
                userItemDrug: this.state.userItemDrugData,
                userItemDose: this.state.userItemDoseData

                // userid: userid
                // jotid: this.createJotid()
            };
            try {
                await this.createTreatment(treatment,idToken);
                alert(JSON.stringify(treatment))
                this.props.history.push("/");
            }catch (e) {
                alert(e);
            }
        }
   }
   createTreatment(treatment,idToken) {
       let myInit = {
           headers: { Authorization: idToken },
           body: treatment
       }
       return API.post("casamia", "/casamiabackend1", myInit);
   }
   /*
   createJotid(){
       let dt = new Date().getTime();
       let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
           let r = (dt + Math.random()*16)%16 | 0;
           dt = Math.floor(dt/16);
           return (c=='x' ? r :(r&0x3|0x8)).toString(16);
       });
       return uuid;
   }
   */
   render() {
       return (
            <div className="Home">
                <div className="col-md-4"> 
                    <Form>
                        <Row>
                            <Col>
                            <FormLabel>Dal</FormLabel><br/>
                            <DatePicker
                                selected={ this.state.userTreatmentFromData }
                                onChange={ this.onChangeFrom }
                                name="userTreatmentFromData"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Data inizio"
                            />
                            </Col>
                            <Col>
                            <FormLabel>Al</FormLabel><br/>
                            <DatePicker
                                selected={ this.state.userTreatmentToData }
                                onChange={ this.onChangeTo }
                                name="userTreatmentToData"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Data fine"
                            />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <FormGroup controlId="userItemDayOfWeekData">
                            <FormLabel>Giorno</FormLabel>
                            <FormControl as="select" onChange={this.handleChange}>
                                <option value="" disabled selected>Scegli giorno</option>
                                <option value="0">Lunedì</option>
                                <option value="1">Martedì</option>
                                <option value="2">Mercoledì</option>
                                <option value="3">Giovedì</option>
                                <option value="4">Venerdì</option>
                                <option value="5">Sabato</option>
                                <option value="6">Domenica</option>
                            </FormControl>
                            </FormGroup>
                            </Col>
                            <Col>
                                <FormLabel>Orario</FormLabel><br/>
                                <TimePicker 
                                onChange={this.onChangeTime}
                                name="userItemTimeData"
                                format={format}
                                inputReadOnly
                                showSecond={false}
                                minuteStep={15}
                                placeholder="orario"
                                locale="it"
                                //value={this.state.userItemTimeData}
                                />
                            </Col>
                        </Row>

                        {/* 
                        userId comes from authentication, no need for form input
                        <Row>
                            <Col>
                            <FormGroup controlId="userIdData">
                                <FormLabel>Id Utente</FormLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.userIdContent}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            </Col>
                        </Row> */}

                        <FormGroup controlId="userTreatmentIdData" >
                            <FormLabel>Id trattamento</FormLabel>
                            <FormControl
                                value={this.state.userTreatmentIdContent}
                                onChange={this.handleChange}
                                type="text"
                                placeholder="codice trattamento (es. 2021_1)"
                            />
                        </FormGroup>
                        <FormGroup controlId="userItemDrugData" >
                            <FormLabel>Medicina</FormLabel>
                            <FormControl
                                //value={this.state.userItemDrugContent}
                                onChange={this.handleChange}
                                type="text"
                                placeholder="Descrizione medicine"
                            />
                        </FormGroup>
                        <FormGroup controlId="userItemDoseData" >
                            <FormLabel>Dosi</FormLabel>
                            <FormControl
                                //value={this.state.userItemDoseContent}
                                onChange={this.handleChange}
                                type="text"
                                placeholder="Dosi da assumere"
                            />
                        </FormGroup>
                        <Button onClick={this.createNew}>
                        +
                        </Button>
                            
                    </Form>
                </div>
            </div>      
       );
   }
}