export default {
   apiGateway: {
       REGION: "eu-west-1",
       URL: "https://gqku5db6bc.execute-api.eu-west-1.amazonaws.com/prod"
   },
   cognito: {
       REGION: "eu-west-1",
       USER_POOL_ID: "eu-west-1_9C0EIyLux",
       APP_CLIENT_ID: "3s93tiaguumt18kl48pdbttb4q",
       IDENTITY_POOL_ID: "eu-west-1:70a2099c-450a-4938-9de8-6ac517988858"
   }
};