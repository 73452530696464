import React, {Fragment} from 'react';
import { Form, Row, Col, FormGroup, FormControl, FormLabel, Button } from "react-bootstrap";
//import CKEditor from '@ckeditor/ckeditor5-react';
//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
//import Select from 'react-select';
import { API, Auth } from "aws-amplify";
import queryString from "query-string";

import moment from "moment";
/* import { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
registerLocale('it', it) */

const userIdContent = "";
const userTreatmentIdContent = "";
const format = 'HH:mm';

export default class ViewTreatment extends React.Component {
    constructor(props, match) {
        super(props,match);
        this.state = {
            userIdData: userIdContent,
            userTreatmentIdData: userTreatmentIdContent,
            userTreatmentFromData: "",
            userTreatmentToData: "",
            userItemIdData: "",
            userItemDayOfWeekData: "",
            userItemTimeData: "",
            userItemDrugData: "",
            userItemDoseData: "",
            edit: false
        }
    };
        
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onChangeFrom = userTreatmentFromData => this.setState({ userTreatmentFromData })
    onChangeTo = userTreatmentToData => this.setState({ userTreatmentToData })
    onChangeTime = userItemTimeData => this.setState({ userItemTimeData })

   validateTreatment = () =>  {
    return this.state.userIdData.length > 0 && this.state.userTreatmentIdData > 0;
   }

   editTreatment = () => {
       this.setState({edit:true})
   }

   saveTreatment = async event => {
       event.preventDefault();
       let treatment = {
          userID: this.state.userIdData,
          userTreatmentId: this.state.userTreatmentIdData,
          userTreatmentFrom: this.state.userTreatmentFromData,
          userTreatmentTo: this.state.userTreatmentToData,
          userItemId: this.state.userItemIdData,
          userItemDayOfWeek: this.state.userItemDayOfWeekData,
          userItemTime: this.state.userItemTimeData,
          userItemDrug: this.state.userItemDrugData,
          userItemDose: this.state.userItemDoseData
        };
        try {
            let sessionObject = await Auth.currentSession();
            let idToken = sessionObject.idToken.jwtToken;
            await this.saveTreatmentData(treatment,idToken);
            this.props.history.push("/");
        }catch (e) {
            alert(e);
        }
   }
   saveTreatmentData(treatment, idToken) {
       let myInit = {
           headers: { Authorization: idToken},
           body: treatment
       }
       return API.post("casamia", "/casamiabackend1", myInit)
   }

   async componentDidMount(){
       try{
           let sessionObject = await Auth.currentSession();
           let idToken = sessionObject.idToken.jwtToken;
           let treatment = await this.getTreatment(idToken);
           // showing selected record
           alert(JSON.stringify(treatment['body']));
           // date conversion from ISO-8601 (String) formatted strings (DynamoDB) to Date
           // DatePicker controls accept only ""Date" type as "selected" property
           var df = new Date(treatment['body'][0].usrTreatmentFrom);
           var dt = new Date(treatment['body'][0].usrTreatmentTo);
            // time conversion from ISO-8601 (String) formatted strings (DynamoDB) to hh:mm
           var ti = moment(treatment['body'][0].usrItemTime,"hh:mm");
           this.setState({
              userIdData: treatment['body'][0].usrId,
              userTreatmentIdData:treatment['body'][0].usrTreatmentId,
              userTreatmentFromData: df,
              userTreatmentToData: dt,
              userItemIdData: treatment['body'][0].usrItemId,
              userItemDayOfWeekData: treatment['body'][0].usrItemDayOfWeek,
              userItemTimeData: ti,
              userItemDrugData: treatment['body'][0].usrItemDrug,
              userItemDoseData: treatment['body'][0].usrItemDose
           });
       }catch(e){
           alert(e);
       }
   }

   getTreatment(idToken) {
       let queryString = require('query-string');
       let params = queryString.parse(this.props.location.search);
       let path = "/casamiabackend3?itemid="+params.itemid+"&userid="+params.userid;
       let myInit = {
           headers: { Authorization: idToken }
       }
       return API.get("casamia", path, myInit);
   }
   render() {
       return (
           <Fragment>
               <div className="Home">
                <div className="col-md-4"> 
                    <Form>
                    {!this.state.edit &&
                        <div style={{margin: "2%", textAlign:'center'}}>
                            <Button
                                style={{width: "50%"}}                           
                                onClick={this.editTreatment}
                            >
                                Edit
                            </Button>
                        </div>}
                        {this.state.edit &&
                        <div style={{margin: "2%", textAlign:'center'}}>
                            <Button
                                style={{width: "50%"}}
                                onClick={this.saveTreatment}
                            >
                                Save
                            </Button>
                        </div>}

                        <Row>
                            <Col>
                            <FormLabel>Dal</FormLabel><br/>
                            <DatePicker
                                selected={ this.state.userTreatmentFromData }
                                disabled={!this.state.edit}
                                onChange={ this.onChangeFrom }
                                name="userTreatmentFromData"
                                dateFormat="dd/MM/yyyy"
                                //locale="it"
                            />
                            </Col>
                            <Col>
                            <FormLabel>Al</FormLabel><br/>
                            <DatePicker
                                selected={ this.state.userTreatmentToData }
                                disabled={!this.state.edit}
                                onChange={ this.onChangeTo }
                                name="userTreatmentToData"
                                dateFormat="dd/MM/yyyy"
                            />
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <FormLabel>Giorno</FormLabel><br/>
                                <select id="userItemDayOfWeekData"
                                    value={this.state.userItemDayOfWeekData}
                                    onChange={this.handleChange}>
                                    <option value="0">Lunedì</option>
                                    <option value="1">Martedì</option>
                                    <option value="2">Mercoledì</option>
                                    <option value="3">Giovedì</option>
                                    <option value="4">Venerdì</option>
                                    <option value="5">Sabato</option>
                                    <option value="6">Domenica</option>
                                </select>
                            </Col>
                            <Col>
                                <FormLabel>Orario</FormLabel><br/>
                                <TimePicker 
                                onChange={this.onChangeTime}
                                name="userItemTimeData"
                                format={format}
                                inputReadOnly
                                showSecond={false}
                                minuteStep={15}
                                value={this.state.userItemTimeData}
                                />
                            </Col>
                        </Row>
                        {/* 
                        <FormGroup controlId="userIdData">
                            <FormLabel>Id Utente</FormLabel>
                            <FormControl
                                autoFocus
                                type="text"
                                value={this.state.userIdData}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        */}
                        <FormGroup controlId="userTreatmentIdData" >
                            <FormLabel>Id trattamento</FormLabel>
                            <FormControl
                                value={this.state.userTreatmentIdData}
                                onChange={this.handleChange}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup controlId="userItemDrugData" >
                            <FormLabel>Medicina</FormLabel>
                            <FormControl
                                value={this.state.userItemDrugData}
                                onChange={this.handleChange}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup controlId="userItemDoseData" >
                            <FormLabel>Dosi</FormLabel>
                            <FormControl
                                value={this.state.userItemDoseData}
                                onChange={this.handleChange}
                                type="text"
                            />
                        </FormGroup>
                            
                    </Form>
                </div>
            </div>                
           </Fragment>
       );
   }
}