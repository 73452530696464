import React, {Component} from 'react';

export default class Footer extends Component {

render() {
    return (
        <div className="footer bg-light">
            {this.props.firstrow}
        </div>)
    }
}