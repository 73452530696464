import React from 'react';

export default class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render(){
    return (
       <h1>NotFound</h1>
    );
  }
}