import React from 'react';
import {Button, Table} from 'react-bootstrap';
import {API, Auth} from 'aws-amplify';
import { Link } from "react-router-dom";
import moment from "moment";

export default class AllTreatments extends React.Component{
   constructor(props) {
       super(props);
       this.state = {
           treatmentlist: []
       }
   };
   
   handleDelete = async (index) => {
       let dtreatment = this.state.treatmentlist[index];
       let sessionObject = await Auth.currentSession();
       let idToken = sessionObject.idToken.jwtToken;
       let userid = sessionObject.idToken.payload.sub;
       try {
           const status = await this.DeleteTreatment(dtreatment.usrItemId,userid,idToken);
           const data = await this.treatments(userid,idToken);
           this.setState({ treatmentlist: data['body'] });
       } catch (e) {
           alert(e);
       }
   }

   DeleteTreatment(itemid,userid,idToken) {
        let path = "/casamiabackend4?itemid="+itemid+"&userid="+userid;
        let myInit = {
           headers: { Authorization: idToken }
        }
        return API.del("casamia", path, myInit);
   }

   handleEdit = async (index) => {
       let treatmentlist = this.state.treatmentlist;
       let sessionObject = await Auth.currentSession();
       let idToken = sessionObject.idToken.jwtToken;
       let userid = sessionObject.idToken.payload.sub;
       let path = "/casamiabackend3?itemid="+treatmentlist[index].usrItemId+"&userid="+userid;
       this.props.history.push(path);
   }
   
   async componentDidMount() {
       if (!this.props.isAuthenticated) {
           return;
       }
       try {
           let sessionObject = await Auth.currentSession();
           let idToken = sessionObject.idToken.jwtToken;
           let userid = sessionObject.idToken.payload.sub;
           const data = await this.treatments(userid,idToken);         
           //alert(JSON.stringify(data['body']));
           this.setState({ treatmentlist: data['body'] });

       } catch (e) {
           alert(e);
       }
   }

   treatments(userid,idToken) {
       let path = "/casamiabackend2?userid="+userid;
       let myInit = {
           headers: { Authorization: idToken },
           queryStringParameters: {
                name: 'userid'
           }
       }
       return API.get("casamia", path, myInit);
   }

   render(){
       if(this.state.treatmentlist == 0)
           return(<h3> <Link to="/casamiabackend1">Aggiungi trattamento</Link> all'elenco</h3>)
       else
           return(
               <div className="col-md-12">
               <Table style={{"marginTop":"2%"}} striped bordered hover responsive>
                   <thead>
                       <tr>
                           <th scope="col">#</th>
                           {/* <th scope="col">Last modified</th> */}
                           <th scope="col">ID Trattamento</th>
                           <th scope="col">ID Utente</th>
                           <th scope="col">ID Item</th>
                           <th scope="col">Dal</th>
                           <th scope="col">Al</th>
                           <th scope="col">Giorno</th>
                           <th scope="col">Ora</th>
                       </tr>
                   </thead>
                   <tbody>
                        {
                            this.state.treatmentlist.map((treatment, index) => {
                                // date conversion
                                var df=moment(treatment.usrTreatmentFrom).format("DD/MM/yyyy");
                                var dt=moment(treatment.usrTreatmentTo).format("DD/MM/yyyy");
                                // weekdays array
                                var weekDays = ['lunedì','martedì','mercoledì','giovedì','venerdì','sabato','domenica'];
                                return(
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        {/* <td>{treatment.lastmodified}</td> */}
                                        <td>{treatment.usrTreatmentId}</td>
                                        <td>{treatment.usrId}</td>
                                        <td>{treatment.usrItemId}</td>
                                        <td>{df}</td>
                                        <td>{dt}</td>
                                        <td>{weekDays[treatment.usrItemDayOfWeek]}</td>
                                        <td>{treatment.usrItemTime}</td>
                                        <td>
                                            <Button variant="outline-info" size="sm"
                                                type="button"
                                                onClick={()=>this.handleEdit(index)}
                                                >Edit
                                            </Button>
                                            {" | "}
                                            <Button variant="outline-danger" size="sm"
                                                type="button"
                                                onClick={()=>this.handleDelete(index)}
                                                >Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                   </tbody>

               </Table>
               </div>   
           );
   }
}