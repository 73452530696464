import React from "react";
import CreateTreatment from './CreateTreatment';
import AllTreatments from './AllTreatments';
import Login from './Login';
import Signup from './Signup';
import NotFound from './NotFound';
import ViewTreatment from './ViewTreatment';
import { Route, Switch } from "react-router-dom";
import AuthorizedRoute from "./AuthorizedRoute";
import UnAuthorizedRoute from "./UnAuthorizedRoute";

export default (cprops) =>
<Switch>

  <UnAuthorizedRoute path="/login" exact component={Login} cprops={cprops}/>
  <UnAuthorizedRoute path="/signup" exact component={Signup} cprops={cprops}/>
 
  <AuthorizedRoute exact path="/casamiabackend1" component={CreateTreatment} cprops={cprops}/>
  <AuthorizedRoute exact path="/casamiabackend2" component={AllTreatments} cprops={cprops}/>
  <AuthorizedRoute exact path="/casamiabackend3" component={ViewTreatment} cprops={cprops}/>
  <AuthorizedRoute exact path="/" component={AllTreatments} cprops={cprops}/>
 
  <Route component={NotFound} />
</Switch>;